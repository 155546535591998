import * as React from "react"
import { Link } from "gatsby"
import { centerHeading } from '../components/layout.module.css'

// styles
const pageStyles = {
  color: "var(--color-front)",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
}

const paragraphStyles = {
  marginBottom: 48,
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 className={centerHeading}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry, we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
